import React from 'react';

import Result from '../types/Result';
import ResultBox from './ResultBox';

import '../style/result.scss';

interface ResultProps {
    results: Result[]
}

const ResultContainer: React.FC<ResultProps> = ({
    results
}) => {

  return (
    <div className='flex flex-wrap result'>
        {results.map((result) => (
            <ResultBox key={result.url} result={result} />
        ))}
    </div>
  )
};

export default ResultContainer;
