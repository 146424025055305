import React from 'react'
import SearchComponent from '../components/SearchComponent';

type Props = {}

const Home = (props: Props) => {
  return (
    <SearchComponent/>
  )
}

export default Home;