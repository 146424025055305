import React from 'react';
import logo from './logo.svg';
import './App.scss';

import {NextUIProvider} from "@nextui-org/react";
import { useNavigate} from 'react-router-dom';
import MyRouter from './MyRouter';
import Header from './components/Header';

function App() {
  const navigate = useNavigate();

  return (
    <NextUIProvider navigate={navigate}>
      <main className="dark text-foreground bg-background react-body">
        <Header/>
        <div className="content">
          <MyRouter/>
        </div>
      </main>
    </NextUIProvider>
  );
}


export default App;
