import React, { useState, useEffect } from 'react';
import { Card, CardBody, Input, Button, CardFooter, Divider, Image, CardHeader, Link } from '@nextui-org/react';
import  '../style/search.scss';
import { fetchData } from '../util/fetchData';
import Result from '../types/Result';
import ResultContainer from './ResultContainer';

interface SearchComponentProps {
  placeholder?: string; // Optional placeholder text for the input
}

const SearchComponent: React.FC<SearchComponentProps> = ({
  placeholder = 'Search...',
}) => {
  const [searchTerm, setSearchTerm] = React.useState('');
  const [result, setData] = useState<Result[] | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null)

  const handleSearch = () => {
    const encodedSearchTerm = encodeURIComponent(searchTerm)
    fetchData(`/search/${encodedSearchTerm}`).then(
      (response) => {
        setData(response);
        setIsLoading(false);
      },
      (error) => {
        setError(error);
        setIsLoading(false);
      }
    )
  };

  useEffect(() => {
  }, []);

  return (
    <section className="search-section">
      <Card className="search-box">
        <CardHeader className="flex gap-3">
          <div className="flex flex-col">
            <h2 className="text-md">Search</h2>
          </div>
        </CardHeader>
        <Divider/>
        <CardBody>
        <Input
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder={placeholder}
              fullWidth
              onClear={() => setSearchTerm('')} // Clear search term on clear button click
            />
        </CardBody>
        <Divider/>
        <CardFooter className='justify-center'>
          <Button color="primary" onClick={handleSearch} variant="solid" isLoading={isLoading}>
            Search
          </Button>
          <p> {error ? error : ''}</p>
        </CardFooter>
      </Card>

      {
        result ?<ResultContainer results={result} /> : null
      }

    </section>
  );
};

export default SearchComponent;

