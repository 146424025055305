import React from 'react'

import Result from '../types/Result';

import {Card, CardBody, Image, Button, Slider, Link} from "@nextui-org/react";

type Props = {
    result: Result
}

 const ResultBox = ({
  result
 }: Props) => {

  const { featured_image, title, url, short_description } = result || {}
  return (
    <Card
      isBlurred
      className="border-none bg-background/60 dark:bg-default-100/50 width-50 result-single"
      shadow="sm"
    >
      <CardBody>
        <div className='flex'>
          <div className="w-40 mr-4">
            <Link isExternal href={url} showAnchorIcon>
              <Image
                alt={title}              
                isZoomed
                className="object-cover"
                shadow="md"
                src={featured_image}
              />
            </Link>
          </div>
          <div className='w-60 ml-4'>
            <h2> <Link isExternal href={url} showAnchorIcon> {title} </Link></h2>
            <p className='mb-16'> {short_description}</p>
            <Button
              href={url}
              as={Link}
              className='self-center'
              isExternal={true}
              color="primary"
              showAnchorIcon
              variant="solid"
            >
            Visit the article
            </Button>
          </div>
        </div>
      </CardBody>
    </Card>
  );
}

export default ResultBox;